@tailwind base;
@tailwind components;
@tailwind utilities;




@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.rotate-circle-container {
  width: 300px; /* Adjustable diameter */
  height: 300px; /* Adjustable diameter */
  position: relative;
  /* animation: rotate 10s linear infinite; */
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



.michroma-regular {
  font-family: "Michroma", serif;
  font-weight: 400;
  font-style: normal;
}


@keyframes float3 {
  0% { transform: translateY(5); }
  50% { transform: translateY(-4px); }
  100% { transform: translateY(0); }
}



.floating-circle2 {
  animation: float3 3s ease-in-out infinite;
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* tailwind.css or styles.css */
@layer components {
  @keyframes scroll {
      from {
          transform: translateX(0);
      }
      to {
          transform: translateX(-50%);
      }
  }

  .animate-scroll {
      display: flex;
      width: max-content;
      animation: scroll 15s linear infinite;
  }
}



@keyframes float {
  50% { transform: translateY(-25px); }
  0%, 100% { transform: translateY(25); }
}

.animate-float {
  animation: float 5s ease-in-out infinite;
}


@keyframes float2 {
  0%, 100% { transform: translateY(10px); }
   50% { transform: translateY(-25px); }
}
.animate-float2 {
  animation: float2 5s ease-in-out infinite;
}

